export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const onImg = require("../assets/image_ (2).png");
export const emailImg = require("../assets/email.png");
export const passImg = require("../assets/pass.png");
export const tick = require("../assets/tick.svg");
export const userImg = require("../assets/user.png");
export const checkImg = require("../assets/checkbox_.svg");
export const lockImg = require("../assets/lock1.png");
export const greenTick = require("../assets/greentick.png");
export const greenPass = require("../assets/greenpass.svg");
export const redPass = require("../assets/redpass.svg");
export const greenUser = require("../assets/greenuser.svg");
export const backbutton = require("../assets/backButton.svg");
export const checkedCheck = require("../assets/checkedCheck.svg");
export const backGround = require("../assets/background.jpeg");
export const helpTitle = require("../assets/helpTitle.svg");
export const unChecked = require("../assets/uncheck.svg");
export const otpImg = require("../assets/otpImg.svg");
