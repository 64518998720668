import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { forgotImg,passImg  } from "./assets";

// Customizable Area End


import NewPasswordController, { Props } from "./NewPasswordController.web";
import { ArrowBackIos, Visibility, VisibilityOff } from "@material-ui/icons";



export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {

    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
    
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
       <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
      

       <Box
         style={{
           flex: 1,
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'center',
           alignItems: 'center',
           padding: 4,
           minHeight:"100vh",
           overflow:"scroll",
           scrollbarWidth:"none"
         }}
       >
         
         <Box style={{display:"flex",flexDirection:"column"}}>
         <Box onClick={this.NavigatetoForgotPassword}> 
         <ArrowBackIos style={{maxWidth:"3.5rem",cursor:"pointer",marginBottom:"2rem"}}/></Box>

         <HeadingText>Create new password</HeadingText>
         <SubHeadingText>Create a strong password to secure your account.</SubHeadingText>
         </Box>
        
                 {this.state.apiResponseMessage && (
                <ErrorMsg
                  style={styles.apiForgotFalied}
                >
                  <Typography data-test-id="apiResponseMessage">
                   {this.state.apiResponseMessage}
                  </Typography>
                </ErrorMsg>
              )}

{this.state.apiResponsesuccessMessage && (
                <ErrorMsg
                  style={styles.apiForgotSuccess}
                >
                  <Typography data-test-id="apiResponsesuccessMessage">
                   {this.state.apiResponsesuccessMessage}
                  </Typography>
                </ErrorMsg>
              )}
               
                  <InputField
                   data-test-id="passwordTestID"
                    id="password"
                    placeholder="Password"
                    onChange={(event) => this.handleNewPassword(event.target?.value)}
                    value={this.state.newPassword}
                    fullWidth
                    type={this.state.passwordVisible ? "" : "password"}
                    variant="outlined"
                    error={this.state.errorColorshow} 
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={passImg} alt="Password Icon" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                          data-test-id="showpassword"
                           onClick={this.handleShowPassword}
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            {this.state.passwordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
             
               
                  <InputField
                    id="confirm-password"
                    data-test-id="confirmPasswordTestID"
                    placeholder="Confirm Password"
                    value={this.state.confirmPassword}
                    onChange={(event) => this.handleConfirmPassword(event.target?.value)}
                    type={this.state.confirmPasswordVisible ? "" : "password"}
                    variant="outlined"
                    fullWidth
                    error={this.state.errorColorshow} 
                    InputProps={{
                      
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={passImg} alt="Password Icon" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleShowConfirmPassword}
                            edge="end"
                          >
                            {this.state.confirmPasswordVisible ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                   <SubmitBtn
                variant="contained"
                // style={styles.buttonStyle}
                data-test-id="nextBtnTestId"
                onClick={this.handleSetNewPassword}
              >
                Submit
              </SubmitBtn>
           
       </Box>
       <ImageGrid
         sx={{
           flex: 1,
        
         }}
       >
         <img 
           src={forgotImg} 
           alt="Login" 
           style={{ width: '100%',maxHeight:"100vh",height:"100vh" }} 
         />
       </ImageGrid>
 
    
    
     </Box>
       // Customizable Area End
    );
  }
}
// Customizable Area Start
const InputField = styled(TextField)({
 
  "@media (max-width:1350px )": {
    width:"500px"
   },
   "@media (max-width:1100px )": {
    width:"450px"
   },
   "@media (max-width:500px )": {
    width:"80%"
   },
  maxWidth:"647px", width:"647",
  marginBottom: "24px",
  "& input::placeholder": {
    color: "#94A3B8", opacity: 1,
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",textTransform: "uppercase",
    color: "#334155",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",fontFamily: "Poppins",
    fontWeight: 400,lineHeight: "18px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171",
  },

  "& .MuiInputBase-root": {
    color: "#334155",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",borderWidth: "1px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px", borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#6EE7B7",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",  borderColor: "#6EE7B7",
    },
  },

  "& .MuiOutlinedInput-input": {
    padding: "20px 8px",
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "0px", marginRight: "0px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "@media (max-width:990px )": {
    minWidth: "unset", maxWidth: "unset",
  },
});

 const ImageGrid = styled(Box)({
  "@media (max-width:1050px )": {
    display: "none",
  },
});


const HeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "48px ",
  },

  "@media (max-width:899px )": {
    fontSize: "32",
  },
 
    fontFamily: "Poppins",
    fontSize: "56px ", display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    fontWeight: 600, marginBottom: "9px ",
    color:"#0F172A",
    

});

const SubHeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "18px ",
  },
  "@media (max-width:899px )": {
    fontSize: "16",
  },
  display: "flex",justifyContent: "center",
  transition: "margin-bottom 0.3s ease",
  fontFamily: "Poppins ", fontSize: "22px ",
  fontWeight: 400,color:"#0F172A",
  marginBottom: "64px",
    

});

const ErrorMsg = styled(Box)({
  "@media (max-width:1350px )": {
    width:"500px"
   },
   "@media (max-width:1100px )": {
    width:"426px"
   },
   "@media (max-width:500px )": {
    width:"80%"
   },
   width:"623px"

});


const SubmitBtn = styled(Button)({
  "@media (max-width:1350px )": {
    width:"500px"
   },
   "@media (max-width:1100px )": {
    width:"450px"
   },
   "@media (max-width:500px )": {
    width:"80%"
   },

    background:
      "#527FE2",
    width: "651px",height: "61px",
    padding: "16px",
    gap: "8px", borderRadius: "8px",
    color: "white",
    marginTop: "48px",
    fontFamily: "Poppins ",
    fontSize: "20px ", fontWeight: 600,
    lineHeight: "24px", cursor: "pointer",
    textTransform:"none",
    '&:hover': {
      backgroundColor: "#527FE2", 
      boxShadow: 'none', 
    },
    '&:active': {
      backgroundColor: "#527FE2", 
      boxShadow: 'none', 
    }
});

 const styles: { [key: string]: React.CSSProperties } = {
 
  apiForgotSuccess:{
    borderLeft: "4px solid #059669",
    padding: "12px", backgroundColor: "#D1FAE5",
    marginBottom: "24px",
    // width:"623px"
  },
  apiForgotFalied:{
    borderLeft: "4px solid #DC2626", padding: "12px",
    backgroundColor: "#FEE2E2",
    marginBottom: "24px", 
    // width:"623px"
  },
  buttonStyle: {
    background:
      "#527FE2",
    width: "647px",
    height: "61px", padding: "16px",
    gap: "8px",
    borderRadius: "8px",color: "white",
    marginTop: "48px",
    fontFamily: "Poppins ",
    fontSize: "20px ",
    fontWeight: 600,lineHeight: "24px",
    cursor: "pointer",
    textTransform:"none"
  },
  accountStyleBold: {
    fontFamily: "Poppins ",
    fontSize: "16px ",cursor: "pointer",
    lineHeight: "24px",
    color: "#527FE2",
  },

  resend: {
    display: 'flex',textAlign: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',fontSize: '14px',
    width: '100%',margin: '30px 0px',
    fontWeight: '600'
  },
  resendBtn: {
    marginRight: '4px'
  },
};


// Customizable Area End
