import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showTermsModal:boolean;
  handleHideTerms:()=>void;
  handleCheckboxAccept:()=>void;
  handleCheckboxReject:()=>void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAdminUser: boolean;
  accountId: number;
  termsConds: ITermsConds | null;
  termsCondsList: ITermsConds[];
  isTermsCondsAccepted: boolean;
  isLoading: boolean;
  termsData:string
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  getTermsCondsListCallId: string = "";
  getAccountGroupsCallId: string = "";
  setAcceptanceOfTermsCondsId: string = "";
  GetTermsAndConditionsCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      termsCondsList: [],
      isAdminUser: true,
      termsConds: null,
      isTermsCondsAccepted: false,
      isLoading: false,
      termsData:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.GetTermsAndConditions()
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  navigateToTermsCondsDetail = (termsCondsId: string) => {
    
  };

  navigateToTermsCondsEdit = () => {
    
  };

  getAccountGroups = async (token: string) => {

  };

  getTermsCondsList = async (token: string) => {
   
 

  };

  getTermsConds = async (token: string) => {
  
 
  };

  handleCheckBoxChange = (value: boolean) => {
  
  
  };

  GetTermsAndConditions = async () => {

    this.GetTermsAndConditionsCallId = await this.getTermsAndConditionsApiCall({
  
        ContentType: 'application/json',
        methods: 'GET',
        endPoint: 'bx_block_terms_and_conditions/terms',
  
  
  
    })
  }
  getTermsAndConditionsApiCall = async (data: {ContentType:string,methods:string,endPoint:string,}) => {
    const { ContentType, methods, endPoint,  } = data;
    const Header = {
        "Content-Type": ContentType,
    };
    const OTPverify = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    OTPverify.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(Header)
    );
    OTPverify.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    OTPverify.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methods
    );
   
    runEngine.sendMessage(OTPverify.id, OTPverify);
    return OTPverify.messageId;
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
 

    if (apiRequestCallId === this.GetTermsAndConditionsCallId) {
      if (responseJson.data) {
        this.setState({termsData:responseJson.data.description})
      } }
  


   
  
    
    // Customizable Area End
  }
}
