import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { Message } from "../../../framework/src/Message";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import { isValidPhoneNumber } from "react-phone-number-input";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  phoneNumber:string;
  errorMessagePhone:string;
  otpModal:boolean;
  forgotOTPvalue:string;
  apiResponseMessage:string;
  borderColor:string;
  otpVerifyToken:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  verifyPhoneOtpCallId:string="";
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        // .when("password", {
        //   // is: (val) => (val && val.length > 0 ? true : false),
        //   then: Yup.string().oneOf(
        //     [Yup.ref("password")],
        //     configJSON.passwordsMustMatch
        //   ),
        // }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      phoneNumber:"",
      errorMessagePhone:"",
      otpModal:false,
      forgotOTPvalue:"",
      apiResponseMessage:"",
      borderColor:"#CBD5E1",
      otpVerifyToken:""
    };
    // Customizable Area End
  }


  // Customizable Area Start


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

     

        if (apiRequestCallId === this.requestPhoneOtpCallId) {
          if (responseJson.data) {
           this.setState({otpModal:true,forgotOTPvalue:responseJson.data.attributes.pin,
            apiResponseMessage:"The code has been sent",
            borderColor:"#6EE7B7",otpVerifyToken:responseJson.meta.token})

          } else if(responseJson.errors) {
            //Check Error Response
            this.setState({apiResponseMessage:"This Mobile Phone not registered",borderColor:"#FCA5A5",forgotOTPvalue:""})
          }}

          if (apiRequestCallId === this.verifyPhoneOtpCallId) {
            if (responseJson) {
              responseJson?.messages[0].otp === "OTP validation success" && this.NavigatetoNewPassword()
            } else if(responseJson.errors) {
              //Check Error Response
            }}

          this.parseApiCatchErrorResponse(errorReponse);
        
    }
  }


  handlePhoneNumber = (event: any) => {
    if (event && isValidPhoneNumber(event.toString())) {
      this.setState({ phoneNumber: event, errorMessagePhone: "" });
    } else {
      this.setState({
        phoneNumber: event,
        apiResponseMessage:"",
        errorMessagePhone: "This Mobile Phone is invalid, please try again",
      });
    }
  };

  handleNumberError=()=>{
    if(this.state?.phoneNumber && this.state.errorMessagePhone=="")
      {
        this.handleSendOTP()
      }
  }
  handleSendOTP = async () => {

    const data = 
    {
      "data": {
          "attributes": {
              "full_phone_number": this.state.phoneNumber
          }
      }}

    this.requestPhoneOtpCallId = await this.OTPPhoneApiCall({

        ContentType: 'application/json',
        methods: 'POST',
        endPoint: 'bx_block_forgot_password/otps',

        Body: data,


    })
}
  OTPPhoneApiCall = async (data: {ContentType:string,methods:string,endPoint:string,Body:{data:{attributes:{
    full_phone_number:string
  }}}}) => {
    const { ContentType, methods, endPoint, Body } = data;
    const Header = {
        "Content-Type": ContentType,
    };
    const SignInAcoount = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    SignInAcoount.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(Header)
    );
    SignInAcoount.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    SignInAcoount.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methods
    );
    Body &&
        SignInAcoount.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),

            JSON.stringify(Body)
        );
    runEngine.sendMessage(SignInAcoount.id, SignInAcoount);
    return SignInAcoount.messageId;
};

handleVerifyOTP = async () => {

  const data = 
  {
    "data": {
      otp_code:this.state.forgotOTPvalue,
      token:this.state.otpVerifyToken
    }}

  this.verifyPhoneOtpCallId = await this.OTPverifyAPICall({

      ContentType: 'application/json',
      methods: 'POST',
      endPoint: 'bx_block_forgot_password/otp_confirmations',

      Body: data,


  })
}
OTPverifyAPICall = async (data: {ContentType:string,methods:string,endPoint:string,Body:{data:{
  otp_code:string,
  token:string
}}}) => {
  const { ContentType, methods, endPoint, Body } = data;
  const Header = {
      "Content-Type": ContentType,
  };
  const OTPverify = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  OTPverify.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
  );
  OTPverify.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );
  OTPverify.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
  );
  Body &&
  OTPverify.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),

          JSON.stringify(Body)
      );
  runEngine.sendMessage(OTPverify.id, OTPverify);
  return OTPverify.messageId;
};
handleOtpChange=()=>{

}

handleCloseOTPmodal=()=>{
  this.setState({otpModal:false})
}

NavigatetoNewPassword=()=>{
    const navigateMessage: Message = new Message(
        getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "NewPassword"
    );
    navigateMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    
  
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage))
        raiseMessage.addData(getName(MessageEnum.InfoPageBodyMessage), this.state.otpVerifyToken);
        navigateMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
    this.send(navigateMessage)

}

NavigatetoLogin=()=>{
  const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
  );
  navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
  );
  navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
  );
  this.send(navigateMessage)

}
  // Customizable Area End
}
