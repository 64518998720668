import I18n from "i18n-js";
import en from "./en.json";
import ar from "./ar.json";
import { setStorageData } from "../../../framework/src/Utilities";

I18n.translations = {
  en,
  ar,
};

I18n.defaultLocale = "en";
I18n.locale = localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en';
I18n.fallbacks = true;

const switchLanguage = async (locale: any) => {
  await setStorageData("GET_SELECTED_LANGUAGE", locale);
  I18n.locale = locale;
  window.location.reload();
};

export { I18n, switchLanguage };