import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { forgotImg,lockImg  } from "./assets";
import PhoneInput from "react-phone-number-input";
import OtpInput from 'react-otp-input';
import { ArrowBackIos } from "@material-ui/icons";

// Customizable Area End


import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";



export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {

    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
    
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
       <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>

       <Box
         style={{
           flex: 1,
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'center',
           alignItems: 'center',
           padding: 4,
           maxHeight:"100vh",
           overflow:"scroll",
           scrollbarWidth:"none",
          
         }}
       >

         <Box style={{display:"flex",flexDirection:"column", gap:"3rem",width:"100%"}}>
         <ArrowBackIos data-test-id="NavigatetoLogin" onClick={this.NavigatetoLogin} style={styles.backArroe}/>
          <Box style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
         <HeadingText>Forgot Password </HeadingText>
         <SubHeadingText>Enter Your Phone Number to receive the OTP code</SubHeadingText></Box>
         </Box>
         {this.state.errorMessagePhone !="" && this.state.phoneNumber?.length >0 && (
                <ErrorMsg
                 
                >
                  <Typography data-test-id="error">
                   {this.state.errorMessagePhone}
                  </Typography>
                </ErrorMsg>
              )}
                 {this.state.apiResponseMessage && (
                <ErrorMsg
                  style={this.state.forgotOTPvalue?styles.apiForgotSuccess:styles.apiForgotFalied}
                >
                  <Typography data-test-id="apiResponseMessage">
                   {this.state.apiResponseMessage}
                  </Typography>
                </ErrorMsg>
              )}
                <PhoneStyle
                 placeholder="Phone Number"
                  data-test-id="phoneTestID"
                  value={this.state.phoneNumber}
                  onChange={(event: any) => {
                    this.handlePhoneNumber(event);
                  }}
                  phone
                  defaultCountry="AE"
                  countries={[]}
                  // international
                  countryCallingCodeEditable={false}
                  borderColor={this.state.borderColor}
                  onKeyPress={(event: React.KeyboardEvent) => {
                    if (event.key === 'Enter') {
                      this.handleNumberError();
                    }
                  }}
                
                 
                />
                 <SubmitBtn
                variant="contained"
                // style={styles.buttonStyle}
                data-test-id="nextBtnTestId"
                onClick={this.handleNumberError}
              >
                Submit
              </SubmitBtn>
       </Box>
       <ImageGrid
         sx={{
           flex: 1,
        
         }}
       >
         <img 
           src={forgotImg} 
           alt="Login" 
           style={{ width: '100%',maxHeight:"100vh",height:"100vh" }} 
         />
       </ImageGrid>
 
       {this.state.otpModal && (
          <Modal
            open={this.state.otpModal}
            onClose={this.handleCloseOTPmodal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box style={styles.modal}>
              <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <img style={{maxWidth:"160px"}} src={lockImg} alt="lock" />
                <Typography style={{ marginTop: '20px' }}>Enter OTP Code</Typography>

                <OtpInput
                  value={this.state.forgotOTPvalue}
                  inputType="tel"
                  onChange={this.handleOtpChange}
                  containerStyle={{ outline: "none" }}
                  data-test-id="otpinput"
                  numInputs={4}
                  renderInput={(props) => <input {...props} placeholder="0"
                    // style={{
                    //   ...props.style,
                    //   borderColor: this.state.otpError ? 'red' : '#CBD5E1',
                    // }}
                    disabled />}
                  inputStyle={styles.otpInput}
                 
                />

                <Button
                  variant="contained"
                  style={styles.otpButton}
                  onClick={this.handleVerifyOTP}
                  data-test-id="nextBtnTestIdotp"
                // onClick={() => this.handleAllValidation()}
                >
                  Submit
                </Button>
                <Box   
                >
                  <Box onClick={this.handleSendOTP}>
                <Typography style={styles.resendText} >
                  <span style={styles.ResendStyle}>Resend</span> Code
                </Typography>
                </Box>
                </Box>
              </Box>
            </Box>
          </Modal>

        )
        }
    
     </Box>
       // Customizable Area End
    );
  }
}
// Customizable Area Start
const InputField = styled(TextField)({
  minWidth: "485px",
  marginBottom: "24px",
  "& input::placeholder": {
    color: "#94A3B8",
    opacity: 1,
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    textTransform: "uppercase",
    color: "#334155",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171",
  },

  "& .MuiInputBase-root": {
    color: "#334155",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#CBD5E1",
    },
  },

  "& .MuiOutlinedInput-input": {
    padding: "20px 8px",
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "@media (max-width:990px )": {
    minWidth: "unset",
    maxWidth: "unset",
  },
});

 const ImageGrid = styled(Box)({
  "@media (max-width:1050px )": {
    display: "none",
  },
});


const HeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "48px ",
  },

  "@media (max-width:899px )": {
    fontSize: "32",
  },
 
    fontFamily: "Poppins",
    fontSize: "56px ",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    marginBottom: "9px ",
    color:"#0F172A",
    

});


const ErrorMsg = styled(Box)({
  "@media (max-width:1350px )": {
    width:"500px"
   },
   "@media (max-width:1100px )": {
    width:"450px"
   },
   "@media (max-width:500px )": {
    width:"80%"
   },
   borderLeft: "4px solid red",
   padding: "12px",
   backgroundColor: "#FEE2E2",
   marginBottom: "24px",
   width:"623px"
  

});

const SubHeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "18px ",
  },
  "@media (max-width:899px )": {
    fontSize: "16",
  },
 
  display: "flex",
  justifyContent: "center",
  transition: "margin-bottom 0.3s ease",
  fontFamily: "Poppins ",
  fontSize: "22px ",
  fontWeight: 400,
  color:"#0F172A",
  marginBottom: "64px",
    

});


const SubmitBtn = styled(Button)({
  "@media (max-width:1350px )": {
    width:"500px"
   },
   "@media (max-width:1100px )": {
    width:"460px"
   },
   "@media (max-width:500px )": {
    width:"80%"
   },
    background:
      "#527FE2",
    width: "651px",
    height: "61px",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "white",
    marginTop: "48px",
    fontFamily: "Poppins ",
    fontSize: "20px ",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    textTransform:"none",
    '&:hover': {
      backgroundColor: "#527FE2", 
      boxShadow: 'none', 
    },
    '&:active': {
      backgroundColor: "#527FE2", 
      boxShadow: 'none', 
    }
});

 const styles: { [key: string]: React.CSSProperties } = {
 backArroe:{
  maxWidth:"3.5rem",cursor:"pointer",marginLeft:"5rem",padding:"1rem"
 },
  apiForgotSuccess:{
    borderLeft: "4px solid #059669",
    padding: "12px",
    backgroundColor: "#D1FAE5",
    marginBottom: "24px",
  },
  apiForgotFalied:{
    borderLeft: "4px solid #DC2626",
    padding: "12px",
    backgroundColor: "#FEE2E2",
    marginBottom: "24px",
  },
  buttonStyle: {
    background:
      "#527FE2",
    width: "647px",
    height: "61px",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "white",
    marginTop: "48px",
    fontFamily: "Poppins ",
    fontSize: "20px ",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    textTransform:"none"
  },
  accountStyleBold: {
    fontFamily: "Poppins ",
    fontSize: "16px ",
    cursor: "pointer",
    lineHeight: "24px",
    color: "#527FE2",
  },
  modal: {
    position: 
    'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 350,
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: 24,

  },
  otpButton: {
    background:
      "#527FE2",
    width: "350px",
    height: "41px",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "white",
    marginTop: '16px',

    fontFamily: "Poppins ",
    fontSize: "16px ",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
  },
  resendText:{
    marginTop:'40px',
    fontFamily: "Poppins ",
    fontSize: "18px ",
    lineHeight: "26px",
  },
  ResendStyle:{
    fontWeight:700,
    color:"#527FE2",
    cursor:'pointer',
  },

  otpInput: {
    display: 'flex',
    width: '56px',
    height: '56px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 400,
    padding: '0',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    margin: '8px',
  },
  otpError: {
    fontWeight: '400',
    color: '#DC2626',
    width: '100%',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center'
  },
  resend: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    fontSize: '14px',
    width: '100%',
    margin: '30px 0px',
    fontWeight: '600'
  },
  resendBtn: {
    marginRight: '4px'
  },
};

  const PhoneStyle = styled(PhoneInput)(({ borderColor }: any) => ({
    "@media (max-width:1350px )": {
      width:"500px"
     },
     "@media (max-width:1100px )": {
      width:"450px"
     },
     "@media (max-width:500px )": {
      width:"80%"
     },
  border: `1px solid ${borderColor || "#CBD5E1"}`,
  width:"647px",
  borderRadius: 8,
  height: 59,
  marginBottom: "24px",
  zIndex: 1,
  position: "relative",
  display: "flex",
  alignItems: "center",
  paddingLeft: 10,
  "& input": {
    border: "none",
  },
  "& input:focus": {
    border: "none",
    outline: "none",
  },
  "& .PhoneInputInput": {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    background: "transparent",

    lineHeight: "24px",
   
  },
  "& input:focus-visible": {
    border: "none",
    outline: "none",
  },
  "&:focus-within": {
    borderColor: "#6EE7B7",
  },

  
}));
// Customizable Area End
