// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  TextField,
  InputAdornment,
  Card,
  TextareaAutosize,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import * as Yup from "yup";
import Select, { ValueType } from "react-select";
import { AddCircleOutline, Close } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Spinner from "./components/Spinner.web";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import CustomFormController, {
  Props,
  Touched,
  Error,
  Dropdown,
  Data,
} from "./CustomFormController.web";
import { card, vat, home, email, taxino, auth, authId, helpicon,deleteimg, iban, time, branch, categories, location, ophours, topdesign, attatchfile } from "./assets";
import { Autocomplete } from "@material-ui/lab";
const InputField = styled(TextField)({
  "& input::placeholder": {
    color: "#94A3B8", opacity: 1,fontFamily:"Poppins"
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px", textTransform: "uppercase",
    color: "#334155",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px", fontFamily: "Poppins",
    fontWeight: 400, lineHeight: "18px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171",
  },

  "& .MuiInputBase-root": {
    color: "#334155",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1", borderWidth: "1px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px", borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#6EE7B7",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px", borderColor: "#6EE7B7",
    },
  },

  "& .MuiOutlinedInput-input": {
    padding: "20px 8px",
  },

  "& .MuiFormHelperText-contained": {
    marginLeft: "0px", marginRight: "0px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "@media (max-width:990px )": {
    minWidth: "unset", maxWidth: "unset",
  },
});
//@ts-ignore
import TimeRange from "react-time-range";

const BtnBox = styled(Box)({
  display: "flex", flexDirection: "column", alignItems: "center",
  justifyContent: "center", gap: "16px",
  marginTop: "4em",
  "@media (max-width:990px )": {
    display: "flex", flexDirection: "column",
    justifyContent: "start",
  },
});

const CardBox = styled(Card)({
  borderRadius: "40px 40px 0px 0px", padding: "72px 40px 72px 40px",
  "@media (max-width:990px )": {
    padding: "48px 20px 48px 20px",
  },
});
// const BoxDiv = styled(Box)({
//   position: 'absolute',

//   "@media (max-width:1920px)": {
//     top: '515px',
//   },
 
//   "@media (max-width:960px)": {
//     top: '40%',
//   },
//   "@media (max-width:810px)": {
//     top: '22%',
//   },
//   "@media (max-width:500px)": {
//     top: '20%',
//   },

// })
const BoxDiv = styled(Box)({
  position: 'absolute',

  "@media (min-width: 1600px)": {
    top: '515px',
  },

  "@media (min-width: 601px) and (max-width: 1600px)": {
    top: '415px', 
  },

  "@media (min-width: 375px) and (max-width: 600px)": {
    top: '21%', 
  },

  "@media (max-width: 375px)": {
    top: '27%',  
  },
});


const SubmitButn = styled(Button)({
  color: "white",
  borderRadius: "10px",
  backgroundColor: "#527FE2",
  textTransform: "none",
  fontFamily: "Poppins",
  fontWeight:"bold",
  fontSize: "24px",
  padding: "6px 31px",
  maxWidth: "647px",
  height: "56px",
  "&:hover": {
    color: "white",
    backgroundColor: "#527FE2",
  },
  "@media(max-width:600px)": {
    fontSize:"18px",
    padding: "7px 27px",
  },
});

const PhoneStyle = styled(PhoneInput)(({ borderColor }: any) => ({

  border: `1px solid ${borderColor || "#CBD5E1"}`,
  borderRadius: 8,
  height: 56,
  zIndex: 1,
  position: "relative",
  display: "flex",
  alignItems: "center",
  paddingLeft: 10,
  "& input": {
    border: "none",
  },
  "& input:focus": {
    border: "none",
    outline: "none",
  },
  "& .PhoneInputInput": {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    background: "transparent",

    lineHeight: "24px",

  },
  "& input:focus-visible": {
    border: "none",
    outline: "none",
  },
  "& input::placeholder": {
    color: "#94A3B8", opacity: 1,
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px", borderRadius: "8px",
  },
  "&:focus-within": {
    borderColor: "#6EE7B7",
  },


}));
const BranchTYpe: Dropdown[] = [
  { label: "Multiple Branches", value: "2" },
  { label: "Individual Branch", value: "0" },


];


const HeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "20px ",
  },
  "@media (max-width:899px )": {
    fontSize: "18",
  },

  fontFamily: "Poppins", lineHeight: "25px",
  fontSize: "24px", fontWeight: 700, padding: "0 40px"

});

const SubHeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "18px ",
  },
  "@media (max-width:899px )": {
    fontSize: "16",
  },

  fontFamily: "Poppins",
  fontSize: "20px", fontWeight: 700, padding: "0 40px"

});

const styles: { [key: string]: React.CSSProperties } = {
  modal: {
    position:
      'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px 8px 32px 8px',

  },
  subModalDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', flexDirection: 'column'
  },
  topboxstyle: {
    display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%",
    borderBottom: "1px solid #E2E8F0", padding: "24px 0px"
  },
  dailyWorkingHrs: {
    display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%",
  },
  topboxstyle2: {
    display: "flex", justifyContent: "space-between", alignItems: "center",
    padding: "24px 24px"
  },
};

const AcceptBtn = styled(Button)({
  maxWidth: "50%",
  "@media (max-width:1050px )": {
    fontSize: "14px ",
    padding: "12px"
  },
  "@media (max-width:899px )": {
    fontSize: "12",
    padding: "8px"
  },

  background:
    "#527FE2",
  width: "100%",
  padding: "16px",
  gap: "8px",
  borderRadius: "8px",
  color: "white",
  fontFamily: "Poppins ",
  fontSize: "1em",
  fontWeight: 600,
  lineHeight: "24px",
  cursor: "pointer",
  textTransform: "none",
  '&:hover': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: "#527FE2",
    boxShadow: 'none',
  }

});
const Heading = styled(Typography)({
  fontFamily:"Poppins",
              fontWeight:"bold",
              fontSize:"2rem"
              
});
const StyledTextarea = styled(TextareaAutosize)({
  width: '100%',
  border: '1px solid #CBD5E1',
  borderRadius: '10px',
  padding: '1rem',
  fontFamily: 'Poppins',
  fontSize: '1rem',
  '&::placeholder': {
    color: '#94A3B8', opacity:1
  },
  
  
});
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    return Yup.object().shape({
      NationalId: Yup.string().matches(/^\d+$/, "*Please enter a valid National ID number.")
        .required("*Please enter a valid National ID number."),
      VatRegNo: Yup.string().required("*Please enter a valid VAT Registration number."),
      NationalAddress: Yup.string().required("Please enter a valid National Address"),
      BusinessName: Yup.string().required("*Please enter a valid business name"),
      industryCat: Yup.string().required("*Please select your Industry category ."),
      branchSelection: Yup.string().required("*Please choose eithr Individual Branch or Multiple Branches."),
      branchName: Yup.string().required("*Please enter the Branch Name"),
      branchLOcation: Yup.string().required("*Please provide the Branch Location"),
      branchContactNo: Yup.string()
        .test('is-valid-phone', '*Please enter valid contact number for the branch', function (value) {
          return isValidPhoneNumber(value || '');
        })
        .required('*Please enter valid contact number for the branch'),

      branchOperatingHours: Yup.string().required("*Please specify the Branch Operating Hours"),
      businessDescription: Yup.string().required("*Please provide brief Business Description"),
      // file: Yup.string().required("*File is required!").nullable(),
    });
  };



  getErrorMessageNonFormik = (value: string) => {
    return (

      <Typography
        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
      >
        {value}
      </Typography>

    );
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box  >
        <Box style={{ padding: "16px 128px 16px 128px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <img src={helpicon} alt="helpicon" style={{ maxWidth: "160px" }} />
        </Box>
        <img src={topdesign} alt="topdesign" style={{ width: "100%", maxHeight: "65vh", objectFit: "cover" }} />

        <BoxDiv padding={{ xs: "15px 25px 25px 25px", sm: "16px 128px 16px 128px" }} >
      
          <CardBox >
            <Box
              id="modal-title"
              mb="20px"
              fontFamily="Poppins"
              fontWeight="bolder"
              fontSize={{ xs: "20px", sm: "32px" }}
            >
              General Information
            </Box>

            <form onSubmit={this.handleSubmit} noValidate autoComplete="off">

              <Grid container spacing={3}>
                <Grid item sm={6} xs={12} md={6}  >
                  <InputField
                    data-test-id="NationalId"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="National Id *"
                    value={this.state.NationalId}
                    error={this.state.errors.NationalId}
                    onChange={(event) => {
                      const value = event.target.value;
                      const NospaceValue = value?.replace(/\s/g, ''); 
                      this.onValueChange("NationalId", NospaceValue);
                    }}
                    InputProps={{

                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={card}
                            alt="user icon"
                            style={{
                              width: 24,
                              height: 24
                            }} />
                        </InputAdornment>
                      ),

                    }}
                  />
                  {this.state.errors.NationalId && this.getErrorMessageNonFormik(this.state.errors.NationalId)}
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="VatRegNo"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Vat Registration Number *"
                    value={this.state.VatRegNo}
                    error={this.state.errors.VatRegNo}
                    onChange={(event: any) => {
                      const value = event.target.value;
                      const NospaceValue = value?.replace(/\s/g, ''); 
                      this.onValueChange("VatRegNo",NospaceValue);
                    }}

                    InputProps={{

                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={vat}
                            alt="user icon"
                            style={{ width: 24, height: 24 }} />
                        </InputAdornment>
                      ),

                    }}
                  />
                  {this.state.errors.VatRegNo && this.getErrorMessageNonFormik(this.state.errors.VatRegNo)}

                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="NationalAddress"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="National Address *"
                    value={this.state.NationalAddress}
                    error={this.state.errors.NationalAddress}
                    onChange={(event: any) => {
                      this.onValueChange(
                        "NationalAddress",
                        event.target.value
                      );
                    }}
                    InputProps={{

                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={home}
                            alt="user icon"
                            style={{ width: 24, height: 24 }} />
                        </InputAdornment>
                      ),

                    }}
                  />
                  {this.state.errors.NationalAddress && this.getErrorMessageNonFormik(this.state.errors.NationalAddress)}

                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="email"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Support email (Option)"
                    value={this.state.email}
                    onChange={(event: any) => {
                      this.onValueChange("email", event.target.value);
                    }}

                    InputProps={{

                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={email}
                            alt="user icon"
                            style={{ width: 24, height: 24 }} />
                        </InputAdornment>
                      ),

                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    id="modal-title"
                    fontFamily="Poppins"
                    fontWeight="bolder"
                    fontSize={{ xs: "20px", sm: "32px" }}
                  >
                    Company Information
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="BusinessName"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Business name *"
                    value={this.state.BusinessName}
                    error={this.state.errors.BusinessName}

                    onChange={(event: any) => {
                      this.onValueChange(
                        "BusinessName",
                        event.target.value
                      );
                    }}
                    InputProps={{

                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={vat}
                            alt="user icon"
                            style={{ width: 24, height: 24 }} />
                        </InputAdornment>
                      ),

                    }}
                  />
                  {this.state.errors.BusinessName && this.getErrorMessageNonFormik(this.state.errors.BusinessName)}

                </Grid>
                <Grid item sm={6} xs={12} md={6}>

                  <InputField
                    data-test-id="TradingLicense"
                    fullWidth
                    variant="outlined"
                    error={this.state.errors.TradingLicense}
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Trading License *"
                    value={this.state.TradingLicense}

                    onClick={this.handleFileInputClick4}
                    InputProps={{
                      readOnly: true,
                    
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={card}
                            alt="License"
                            style={{ width: 24, height: 24 }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.handleFileInputClick4}>
                            <img
                              src={attatchfile}
                              alt="attachFile"
                              style={{ width: 24, height: 24 }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    data-test-id="TradingLicenseFile"
                    ref={this.state.fileInput4}
                    style={{ display: 'none' }}
                    accept=".pdf"
                    onChange={this.handleFileChange4}
                  />
                  {this.state.errors.TradingLicense && this.getErrorMessageNonFormik(this.state.errors.TradingLicense)}

                </Grid>

                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="VATtaxidentifire"
                    fullWidth
                    variant="outlined"
                    onClick={this.handleFileInputClick3}
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Vat Tax Identifier *"
                    value={this.state.VATtaxidentifire}
                    error={this.state.errors.VATtaxidentifire}
                    onChange={(event: any) => {
                      this.onValueChange("VATtaxidentifire", event.target.value);
                    }}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={taxino}
                            alt="TaxIdentifier"
                            style={{ width: 24, height: 24 }} />
                        </InputAdornment>
                      ),

                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.handleFileInputClick3}>
                            <img
                              src={attatchfile}
                              alt="attachFile"
                              style={{ width: 24, height: 24 }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    data-test-id="VATtaxidentifireFile"
                    ref={this.state.fileInput3}
                    style={{ display: 'none' }}
                    onChange={this.handleFileChange3}
                    accept=".pdf"
                  />
                  {this.state.errors.VATtaxidentifire && this.getErrorMessageNonFormik(this.state.errors.VATtaxidentifire)}

                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="authorizedCertificate"
                    fullWidth
                    variant="outlined"
                    error={this.state.errors.authorizedCertificate}
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Authorization Certificate *"
                    value={this.state.authorizedCertificate}
                    onChange={(event: any) => {
                      this.onValueChange("authorizedCertificate", event.target.value);
                    }}
                    onClick={this.handleFileInputClick2}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={auth}
                            alt="authCertificate"
                            style={{ width: 24, height: 24 }} />
                        </InputAdornment>
                      ),

                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.handleFileInputClick2}>
                            <img
                              src={attatchfile}
                              alt="attachFile"
                              style={{ width: 24, height: 24 }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    ref={this.state.fileInput2}
                    data-test-id="authorizedCertificateFile"
                    style={{ display: 'none' }}
                    onChange={this.handleFileChange2}
                    accept=".pdf"
                  />
                  {this.state.errors.authorizedCertificate && this.getErrorMessageNonFormik(this.state.errors.authorizedCertificate)}
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="authorizedIDcard"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Authorized Id Card *"
                    value={this.state.authorizedIDcard}
                    error={this.state.errors.authorizedIDcard}
                    onChange={(event: any) => {
                      this.onValueChange("authorizedIDcard", event.target.value);
                    }}
                    onClick={this.handleFileInputClick1}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={authId}
                            alt="authidcard"
                            style={{
                              width: 24,
                              height: 24
                            }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.handleFileInputClick1}>
                            <img
                              src={attatchfile}
                              alt="attachFile"
                              style={{ width: 24, height: 24 }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    ref={this.state.fileInput1}
                    data-test-id="authorizedIDcardFile"
                    style={{ display: 'none' }}
                    onChange={this.handleFileChange1}
                    accept=".pdf"
                  />

                  {this.state.errors.authorizedIDcard && this.getErrorMessageNonFormik(this.state.errors.authorizedIDcard)}

                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="ibanCertificate"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="IBAN Certificate *"
                    error={this.state.errors.ibanCertificate}
                    value={this.state.ibanCertificate}

                    onClick={this.handleFileInputClick}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={iban}
                            alt="ibanCertificate"
                            style={{ width: 24, height: 24 }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.handleFileInputClick}>
                            <img
                              src={attatchfile}
                              alt="attachFile"
                              style={{ width: 24, height: 24 }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    data-test-id="ibanCertificateFile"
                    ref={this.state.fileInput}
                    style={{ display: 'none' }}
                    onChange={this.handleFileChange}
                    accept=".pdf"
                  />

                  {this.state.errors.ibanCertificate && this.getErrorMessageNonFormik(this.state.errors.ibanCertificate)}
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <InputField
                    data-test-id="OtherCertificate"
                    fullWidth
                    variant="outlined"
                    
                    inputProps={{
                      style: {
                        height: "16px",
                      },
                    }}
                    placeholder="Add Other Document (Optional)"
                    value={this.state.InputFileOther}

                    onClick={this.handleOtherFileInputClick}
                    InputProps={{
                      readOnly: true,

                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton onClick={this.handleOtherFileInputClick}
                            style={{ padding: "0px" }}>
                            <img
                              src={attatchfile}
                              alt="attachFile"
                              style={{ width: 24, height: 24 }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    data-test-id="OtherCertificateFile"
                    ref={this.state.otherFileInput}
                    style={{ display: 'none' }}
                    onChange={this.OtherhandleFileChange}
                    accept=".pdf"
                  />

                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                  <Autocomplete
                    data-test-id="industryCat"
                    options={this.state.categoryData}
                    getOptionLabel={(option: {
                      "id": '',
                      "name": "",
                      "arabic_name": ""
                    },) => option.name}
                    onChange={(event, newValue) => {
                      this.onValueChange("industryCat", newValue?.id || '');
                    }}
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        placeholder="Categories *"
                        variant="outlined"
                        value={this.state.industryCat || ''}
                        error={this.state.errors.industryCat}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={categories}
                                alt="category icon"
                                style={{ width: 24, height: 24 }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {this.state.errors.industryCat && this.getErrorMessageNonFormik(this.state.errors.industryCat)}
                </Grid>


                <Grid item sm={6} xs={12} md={6}>
                  <StyledTextarea minRows={10}
                    value={this.state.businessDescription}
                    data-test-id="businessDescription"
                    onChange={(event: any) => {
                      this.onValueChange("businessDescription", event.target.value);
                    }}
                    onError={this.state.errors.businessDescription}
                     placeholder="Business Description *" />

                  {this.getErrorMessageNonFormik(this.state.errors.businessDescription)}


                </Grid>

                <Grid item sm={6} xs={12} md={6}>
                  <Autocomplete
                    data-test-id="branchSelection"
                    options={BranchTYpe}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      this.onValueChange("branchSelection", newValue ? newValue.value : null);
                    }}
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        placeholder="Branch Selection *"
                        variant="outlined"
                        value={this.state.branchSelection}
                        error={this.state.errors.branchSelection}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={branch}
                                alt="user icon"
                                style={{ width: 24, height: 24 }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {this.state.errors.branchSelection && this.getErrorMessageNonFormik(this.state.errors.branchSelection)}

                </Grid>
                {this.state.branchSelection?<>
                <Grid item xs={12}>
                 
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb="20px"
                  >
                    <Box
                      id="modal-title"
                      fontFamily="Poppins"
                      fontWeight="bolder"
                      fontSize={{ xs: "20px", sm: "24px" }}
                    >
                      Branch Details
                    </Box>
                  {this.state.branchSelection =="2" ?
                    <Box>
                      <Button style={{ fontSize: "16p", fontFamily: "Poppins", textTransform: 'none', color: "#527FE2" }} data-test-id="addfield" onClick={this.addBranch}>Add Field
                        <AddCircleOutline style={{ marginLeft: "8px" }} /></Button>
                    </Box>:null}
                  </Box>
                </Grid>

                <>
                  {this.state.branches.map((branch: any, index: any) => (
                    <Box key={branch.id} mb={4} width={'100%'} padding={'12px'}>
                      <Box mb={2} style={{display:"flex",justifyContent:"space-between"}}>
                        <strong style={{fontFamily:"Poppins",fontSize:"18px"}}>Branch {index + 1}</strong>
                        {index >0 ?
                        <Button data-test-id={`delete-${index}`} onClick={()=>this.deleteBranch(index)}><img src={deleteimg} alt="deleteimg" style={{height:"24px",width:"24px",cursor:"pointer"}}/></Button>:null}
                      </Box>
                      <Grid container spacing={2}>
                        {/* Branch Name */}
                        <Grid item sm={6} xs={12} md={6}>
                          <InputField
                            data-test-id={`branchName-${index}`}
                            fullWidth
                            variant="outlined"
                            inputProps={{ style: { height: "16px" } }}
                            placeholder="Branch Name *"
                            value={this.state.branches.branchName}
                            error={this.state.errors[`branches[${index}].branchName`]}
                            onChange={(event: any) => this.onValueChangeNew(index, "branchName", event.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={card} alt="Branch Name Icon" style={{ width: 24, height: 24 }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {this.state.errors[`branches[${index}].branchName`] &&
                            this.getErrorMessageNonFormik(this.state.errors[`branches[${index}].branchName`])}
                        </Grid>

                        {/* Branch Location */}
                        <Grid item sm={6} xs={12} md={6}>
                          <InputField
                            data-test-id={`branchLocation-${index}`}
                            fullWidth
                            variant="outlined"
                            inputProps={{ style: { height: "16px" } }}
                            placeholder="Branch Location *"
                            value={this.state.branches.branchLocation}
                            error={this.state.errors[`branches[${index}].branchLocation`]}
                            onChange={(event: any) => this.onValueChangeNew(index, "branchLocation", event.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={location} alt="Location Icon" style={{ width: 24, height: 24 }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {this.state.errors[`branches[${index}].branchLocation`] &&
                            this.getErrorMessageNonFormik(this.state.errors[`branches[${index}].branchLocation`])}
                        </Grid>

                        {/* Branch Contact Number */}
                        <Grid item sm={6} xs={12} md={6}>
                          <PhoneStyle
                            placeholder="Branch Contact Number *"
                            data-test-id={`branchContactNo-${index}`}
                            value={this.state.branches.branchContactNo}
                            onChange={(value: any) => this.onValueChangeNew(index, "branchContactNo", value)}
                            phone
                            defaultCountry="AE"
                            error={this.state.errors[`branches[${index}].branchContactNo`]}
                          />
                          {this.state.errors[`branches[${index}].branchContactNo`] &&
                            this.getErrorMessageNonFormik(this.state.errors[`branches[${index}].branchContactNo`])}
                        </Grid>

                        {/* Branch Operating Hours */}
                        <Grid item sm={6} xs={12} md={6}>
                          <InputField
                            data-test-id={`branchOperatingHours-${index}`}
                            fullWidth
                            variant="outlined"
                            inputProps={{ style: { height: "16px" } }}
                            placeholder="Branch Operating Hours *"
                            value={branch.branchOperatingHours}
                            onClick={() => this.handleShowTime(index)}
                            error={this.state.errors[`branches[${index}].branchOperatingHours`]}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={ophours} alt="Operating Hours Icon" style={{ width: 24, height: 24 }} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="start" data-test-id="modalOperatingHours">
                                  <img
                                    src={time}
                                    alt="Time Icon"
                                    style={{ width: 24, height: 24, cursor: "pointer" }}
                                    onClick={() => this.handleShowTime(index)}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {this.state.errors[`branches[${index}].branchOperatingHours`] &&
                            this.getErrorMessageNonFormik(this.state.errors[`branches[${index}].branchOperatingHours`])}
                          <Modal
                            open={this.state.showMOdaltime}
                            onClose={this.handleHideshowTime}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                          >
                            <Box style={styles.modal}>
                              <Box style={styles.subModalDiv}>
                                <Box style={styles.topboxstyle}>
                                  <HeadingText>Branch Operating Hours</HeadingText>
                                  <Close style={{ paddingRight: "1rem" }} onClick={this.handleHideshowTime} />
                                </Box>

                                <Box style={styles.topboxstyle}>
                                  <SubHeadingText>Weekly Working Days</SubHeadingText>
                                </Box>

                                <Box style={styles.topboxstyle2}>
                                  <FormGroup
                                    style={{ display: "flex", flexDirection: "row", 
                                    padding: "16px 20px", borderBottom: "1px solid #E2E8F0" }}
                                  >
                                    {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((day) => (
                                      <FormControlLabel
                                        key={day}
                                        control={
                                          <Checkbox
                                            style={{ color: "#527FE2" }}
                                            name={day}
                                            checked={this.state.branches[index].selectedDays.includes(day)}
                                            onChange={(event) => this.handleCheckboxChange(index, day, event.target.checked)}
                                          />
                                        }
                                        label={day.charAt(0).toUpperCase() + day.slice(1)}
                                        data-test-id ={day}
                                      />
                                    ))}
                                  </FormGroup>
                                </Box>

                                <Box style={styles.dailyWorkingHrs}>
                                  <SubHeadingText>Daily Working Hours</SubHeadingText>
                                </Box>

                                <Box style={{ padding: "1.5rem 0 1.5rem 0" }}>
                                <TimeRange
                            onStartTimeChange={(event:any)=>this.returnFunctionStart(event ,index)}
                            onEndTimeChange={(event:any)=>this.returnFunctionEnd(event ,index)}
                            startMoment={this.state.branches[index].startTime}
                            endMoment={this.state.branches[index].endTime}

                          />
                                </Box>


                                <Box style={{ display: "flex", justifyContent: "flex-end",
                                 padding: "20px 20px", width: "90%", borderTop: "1px solid #CBD5E1" }}>
                                  <AcceptBtn data-test-id="modalsubmit" onClick={this.onHandleSubmitTime}>Submit</AcceptBtn>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>

                        </Grid>


                      </Grid>
                    </Box>
                  ))}



                  {/* Modal for Setting Operating Hours */}




                </>
                </>:null}
              </Grid>
              <BtnBox >
             {this.state.emailValidation? <Typography
        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
      >
        {this.state.emailValidation}
      </Typography>:null}
                <SubmitButn type="submit" fullWidth>
                  Submit
                </SubmitButn>
                <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "20px" }}>Already have account ?
                  <span data-test-id="login" onClick={this.NavigatetoLogin} style={{ color: "#527FE2", cursor: "pointer" ,fontWeight:"bold"}}> Login</span> </Typography>
              </BtnBox>
            </form>
            {/* )}}
              </Formik> */}
          </CardBox>
        </BoxDiv>

      </Box>
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
