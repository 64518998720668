import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
 
  token: string;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  phoneNumber:string;
  errorMessagePhone:string;
  otpModal:boolean;
  forgotOTPvalue:string;
  apiResponseMessage:string;
  borderColor:string;
  otpVerifyToken:string;
  confirmPasswordVisible:boolean;
  passwordVisible:boolean;
  confirmPassword:string;
  newPassword:string;
  errorColorshow:boolean;
  apiResponsesuccessMessage:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  
  verifyPhoneOtpCallId:string="";
  requestChangePasswordCallId: any;
 
  otpToken: any;
  isChangePassword: boolean = false;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start


 

    this.state = {
      accountType: "sms",
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      phoneNumber:"",
      errorMessagePhone:"",
      otpModal:false,
      forgotOTPvalue:"",
      apiResponseMessage:"",
      borderColor:"#CBD5E1",
      otpVerifyToken:"",
      confirmPasswordVisible:false,
      passwordVisible:false,
      confirmPassword:"",
      newPassword:"",
      errorColorshow:false,
      apiResponsesuccessMessage:""
    };
    // Customizable Area End
  }


  // Customizable Area Start



  async receive(from: string, message: Message) {
    if(getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

        this.handleNavigationPayload(message)
      }
 
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

     

        if (apiRequestCallId === this.requestChangePasswordCallId) {
          if (responseJson.message) {
           this.setState({
            apiResponsesuccessMessage:responseJson.message,
            errorColorshow:false,
            apiResponseMessage:""
           })

          } else if(responseJson.errors) {
            //Check Error 
        
            this.setState({apiResponseMessage:responseJson.errors[0], errorColorshow:true,apiResponsesuccessMessage:""})
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
    }
  }



  handleShowConfirmPassword=()=>{
    this.setState({confirmPasswordVisible:!this.state.confirmPasswordVisible})
  }

  handleShowPassword=()=>{
    this.setState({passwordVisible:!this.state.passwordVisible})
  }

  handleNewPassword=(value:string)=>{
    const NospaceValue = value?.replace(/\s/g, '');
    this.setState({newPassword:NospaceValue})
  }

  handleConfirmPassword=(value:string)=>{
    const NospaceValue = value?.replace(/\s/g, '');
    this.setState({confirmPassword:NospaceValue})
  }
handleSetNewPassword = async () => {

  const data = 
  {
    "data": {
        new_password:this.state.newPassword,
        password_confirmation:this.state.confirmPassword,
        token :this.state?.token
    }}

  this.requestChangePasswordCallId = await this.SetNewPasswordAPIcall({

      ContentType: 'application/json',
      methods: 'PUT',
      endPoint: 'bx_block_forgot_password/passwords/update',

      Body: data,


  })
}
SetNewPasswordAPIcall = async (data: {ContentType:string,methods:string,endPoint:string,Body:{data:{
    new_password:string, password_confirmation:string,
  token:string
}}}) => {
  const { ContentType, methods, endPoint, Body } = data;
  const Header = {
      "Content-Type": ContentType,
  };
  const SignInAcoount = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  SignInAcoount.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
  );
  SignInAcoount.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );
  SignInAcoount.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
  );
  Body &&
      SignInAcoount.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),

          JSON.stringify(Body)
      );
  runEngine.sendMessage(SignInAcoount.id, SignInAcoount);
  return SignInAcoount.messageId;
};

handleNavigationPayload = (message: Message) => {

    const data = message.getData(
      getName(MessageEnum.InfoPageBodyMessage)
    );
    const parsedData = data ;
    this.setState({token:parsedData})

  }


  NavigatetoForgotPassword=()=>{
    const navigateMessage: Message = new Message(
        getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "ForgotPassword"
    );
    navigateMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    this.send(navigateMessage)

}

  // Customizable Area End
}
