import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { emailImg, greenPass, imgPasswordInVisible, imgPasswordVisible, redPass,greenTick, tick } from "./assets";
import { isValidPhoneNumber } from "react-phone-number-input";
import {switchLanguage} from "../../../components/src/languageJson/i18n"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  errorMessageMobile: string;
  errorMessageUserName: string;
  errorMessageEmail: string;
  errorMessagePassword: string;
  passwordError:boolean;
  errorMessageConfirm: string;
  errorMessageTerms: string;
  errorMsg: string;
  userName: string;
  confirmPassword: string;
  confirmPasswordVisible: boolean;
  passwordVisible: boolean;
  otpModal:boolean;
  otpValue: string,
  otpError:string,
  allPasswordField:boolean,
  conditionsMet: {
    hasLowercase: boolean,
    hasUppercase: boolean,
    hasNumber: boolean,
    hasSpecialChar: boolean,
    isLongEnough: boolean,
    noSpaces:boolean
  },
  checked:boolean,
  otpToken :string,
  showTermsModal:boolean,
  focusedConfirm: boolean
  currentLanguage: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signUpApiCallId:string=""
  
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  confirmOTPCallId:string=""
  emailregex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  usernameRegex = /^[\w\s]{3,15}$/;

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // setOtp: Dispatch<SetStateAction<string>>;
 
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      confirmPasswordVisible: false,
      passwordVisible: false,
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      focusedConfirm:false,
      phone: "",
      errorMsg: "",
      errorMessageMobile: "",
      errorMessageUserName: "",
      errorMessageEmail: "",
      errorMessagePassword: "",
      passwordError:false,
      errorMessageConfirm: "",
      errorMessageTerms: "",
      userName: "",
      confirmPassword: "",
      otpModal: false,
      otpToken :"",
      otpValue: '',
      otpError:'',
      allPasswordField:false,
      conditionsMet: {
        hasLowercase: false,
        hasUppercase: false,
        hasNumber: false,
        hasSpecialChar: false,
        isLongEnough: false,
        noSpaces:false
      },
      checked:false,
      showTermsModal:false,
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',

      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.signUpApiCallId){
          this.handleApiSignupResponse(responseJson)
        }
        
      }
  
        if(apiRequestCallId === this.confirmOTPCallId){
          this.handleOtpConfirmResponse(responseJson)
        }
        
      
    }


    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };

  getTickIcon(isMet: boolean): string {
    return isMet ? greenTick : tick;
  }

  getInputType = () => {
    return this.state.passwordVisible ? "" : "password";
  };

  switchToEnglish = () => {
    switchLanguage("en");
  };

  switchToArabic = () => {
    switchLanguage("ar");
  };


  getErrorMessageClassName = (errrorFiled: string, field: string) => {
    if (errrorFiled) {
      return "red";
    } else if (field) {
      return "success";
    } else {
      return "";
    }
  }

  getClassNameIcon = (errrorFiledIcon: string, icon: string) => {
    if (errrorFiledIcon) {
      return redPass;
    } else if (icon) {
      return greenPass;
    } else {
      return emailImg;
    }
  }

  getVisibilityIconColor = (errorIcon: string, sIcon: string) => {
    if (errorIcon) {
      return '#FCA5A5';
    } else if (sIcon) {
      return '#6EE7B7';
    } else {
      return 'inherit';
    }
  };

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  goToCustomForm(){
    const msg = new Message(getName(MessageEnum.NavigationMessage));

    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'CustomForm'
    );

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(msg);

   
  }
  goToLOginSCreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  };

  handleOtpConfirmResponse=(data:{meta:{message:string,token:string}})=>{
    console.log(data.meta.message,"dfghdfg")
    if(data.meta.message){
      localStorage.setItem("tokenCustom",data.meta.token)
      this.handleClose()
      // this.goToLOginSCreen()
      this.goToCustomForm()
    }
  }
  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };


  // My Functions
  handleUser = (value: string) => {
    if (value.length === 0) {
      // Handle empty value case
      this.setState({ userName: value, errorMessageUserName: "" });
    } else if (this.usernameRegex.test(value)) {
      // Handle valid username
      this.setState({ userName: value, errorMessageUserName: "" });
    } else {
      // Handle invalid username
      this.setState({
        userName: value,
        errorMessageUserName:
          "Invalid username. It must be 3-15 characters long and can only contain letters, numbers, and underscores.",
      });
    }
  };


  handlePassword = (value: string) => {
   
    if (value === "") {
      
      this.setState({
        password: value,
        conditionsMet: {
          hasLowercase: false,
          hasUppercase: false,
          hasNumber: false,
          hasSpecialChar: false,
          isLongEnough: false,
          noSpaces: false,
        },
        errorMessagePassword: "",
        allPasswordField: false,
       errorMessageConfirm: "",
      });
     
    }
    
    const conditionsMet = {
      hasLowercase: /[a-z]/.test(value),
      hasUppercase: /[A-Z]/.test(value),
      hasNumber: /\d/.test(value),
      hasSpecialChar: /[\W_]/.test(value),
      isLongEnough: value.length >= 8,
      noSpaces: !/\s/.test(value),
    };
  
    const allConditionsMet = Object.values(conditionsMet).every(Boolean);

    this.setState({
      password: value,
      conditionsMet,
      allPasswordField: allConditionsMet,
      errorMsg:'',
      errorMessagePassword: allConditionsMet ? "" : "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.",
    },()=>{
      
      this.handlePasswordConfirm(this.state.confirmPassword);

    });
    if(value === this.state.confirmPassword || value===""){
      this.setState({
        errorMessagePassword: "",
      })
    }
  };

handleFocus = () => {
this.setState({ focusedConfirm:true })
}

onUnFocused = () => {
  this.setState({ focusedConfirm:false })
  }

  handlePasswordConfirm = (value: string) => {
   
    
    if (value.length > 0) {
      if (value === this.state.password || value==="" ) {
        this.setState({ confirmPassword: value, errorMessageConfirm: "",errorMsg:'' },()=>{

        });
      } else if(value !== this.state.password) {
        this.setState({
          confirmPassword: value,
          errorMessageConfirm: "Passwords do not match.",
        });
      }
    } else if(!this.state.focusedConfirm && !value) {

      this.setState({
        confirmPassword: value,
        errorMessageConfirm: "",
      });
    } else {
      this.setState({
        confirmPassword: value,
  
        errorMessageConfirm: this.state.confirmPassword ? "" : "Enter Password, please try again",
      });
    }
  }

  handleEmail = (value: string) => {
    const errorMessageEmail =
      value.length === 0
        ? "Enter User Name, please try again"
        : !this.emailregex.test(value)
        ? "Invalid email format, please try again"
        : "";
  
    this.setState({
      email: value,
      errorMessageEmail,
     
    });
  };

  handleClickShowPassword = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      confirmPasswordVisible: !this.state.confirmPasswordVisible,
    });
  };

  handleMouseDownConfirmPassword = (event: any) => {
    event.preventDefault();
  };

  getErrorMessage = () => {
    return (
      this.state.errorMessageEmail ||
      this.state.errorMessageUserName ||
      this.state.errorMessagePassword ||
      this.state.errorMessageConfirm ||
      this.state.errorMessageMobile ||
      this.state.errorMessageTerms ||
      this.state.errorMsg
    );
  };

  handlePhoneNumber = (e: any) => {
    if (e && isValidPhoneNumber(e.toString())) {
      this.setState({ phone: e, errorMessageMobile: "",errorMsg:'' });
    } else {
      this.setState({
        phone: e,
        errorMessageMobile: "This Mobile Phone is invalid, please try again",
      });
    }
  };

  handleAllValidation = () => {
    const { email, userName, password, confirmPassword, phone, checked } = this.state;
    if ( !password || !confirmPassword || !phone || !checked) {
      this.setState({ errorMsg: "Please fill all fields."  });
    }
    
    if(!this.getErrorMessage()){
        const requestBody ={
      "data": {
          "type": "sms_account",
          "role": "merchant",
          "attributes": {
              "full_name": this.state.userName,
              "user_name": "KY",
              "full_phone_number": this.state.phone,
              "email": this.state.email,
              "password": this.state.password,
              "password_confirmation": this.state.password,
              "is_terms_and_condition_accepted": this.state.checked
          }
      }
  }
    this.handlePostSignupApi(requestBody)
    }
   

   
  };

  handleUserNameError = () => {
    return this.state.errorMessageUserName && "red" || this.state.userName && "success"
  }

  handlePhonerror = () =>{
    return this.state.errorMessageMobile && "red" || this.state.phone && "success"
  }

  handleEmailerror = () =>{
    return this.state.errorMessageEmail && "red" || this.state.email && "success"
  }

  handlePasswordError = () =>{
    return this.state.errorMessagePassword && "red" || this.state.password && "success"
  }

  handleVisibilityOn = () => {
    return this.state.errorMessagePassword && "red" || this.state.password && "#6EE7B7" || "inherit"
  }

  handleConfPass = () => {
    return this.state.errorMessageConfirm && "#FCA5A5" || this.state.confirmPassword && "success"
  }

  handleConfPassImg = (errMsgConf: any , confPass: any, passImg:any) => {
  if (errMsgConf) {
    return redPass
  } else if (confPass){
   return greenPass
  } else {
     return passImg
  }
  }

  handleVisibilityOff = () => {
    return this.state.errorMessagePassword && "#FCA5A5" || this.state.password && "#6EE7B7" || "inherit"
  }

  handleVisibilityOnConf = () => {
    return this.state.errorMessageConfirm && "#FCA5A5" || this.state.confirmPassword && "#6EE7B7" || "inherit"
  }

  handleVisibilityOffConf = () => {
    return this.state.errorMessageConfirm && "#FCA5A5" || this.state.confirmPassword && "#6EE7B7" || "inherit"
  }

  checkOtpError() {
    if (this.state.otpError) {
      return 'red';
    } else {
      return '#CBD5E1';
    }
  }

  handlePasswordType = (passTypeCeck: any) =>{
    return passTypeCeck ? "" : "password"
  }

  handlePasswordConfType = (confPassTypeCeck: any) =>{
    return confPassTypeCeck ? "" : "password"
  }

  getCheckStatus(item:any ,checkedCheck:any, unChecked:any) {
    return item.isMet ? checkedCheck : unChecked;
  }

  handleUserImage(greenUser:any, userImg:any) {
    return this.state.userName && !this.state.errorMessageUserName ? greenUser : userImg;
}

handlePassTypeCheck = (passTypeCeck: any) =>{
  return passTypeCeck ? "" : "password"
}

handleEmailErrorImg = (errormsgEmail: any, email:any, emailImg: any) =>{
  if (errormsgEmail) {
    return redPass
  } else if (email) {
    return greenPass
  } else {
    return emailImg
  }
}

handlePassErrorImg = (passErr:any, pass:any, passImg:any) => {
  if (passErr) {
    return redPass
  } else if (pass) {
    return greenPass
  } else {
    return passImg
  }
}

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checked: event.target.checked });
  };
  
  handleCheckboxAccept = () => {
    this.setState({ checked: true,showTermsModal:false });
  };

  handleCheckboxReject  = () => {
    this.setState({ checked: false,showTermsModal:false });
  };
  
  handleOtpChange = (newValue : string) => {
    // this.setState({ otpValue: "1234"})
  }

   handleClose = () => {
    this.setState({ otpModal: false});
   
  };

 handlePostSignupApi = async (requestBody: { data: { type: string; role: string; attributes: { full_name: string; user_name: string; full_phone_number: string; email: string; password: string; password_confirmation: string; is_terms_and_condition_accepted: boolean; }; }; }) => {
    const header = {
      "content-type": "application/json"
    };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.signUpApiCallId = requestMessage.messageId;
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.signupEndPoint
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(requestBody)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.apiMethodTypeAddDetail
);

runEngine.sendMessage(requestMessage.id, requestMessage);
return true;
  };

  handleApiSignupResponse = (responseJson: { data: any; errors:string[],meta:any }) => {
    if (responseJson?.data) {
      this.setState({ errorMsg: "", otpModal: true, otpValue:responseJson.meta.pin,otpToken:responseJson.meta.token});
    }else{
      this.setState({errorMessageMobile:responseJson.errors[0]})
    }
   
  };

  handleConfirmOtp = async () => {
    this.handleAllValidation()

    const data = 
    {
       
        "pin": this.state.otpValue
      
    }

    this.confirmOTPCallId = await this.SignupAPICall({

        ContentType: 'application/json',
        methods: 'POST',
        endPoint: 'account_block/sms_confirmations',

        Body: data,


    })
}

 
  SignupAPICall = async (data: {ContentType:string,methods:string,endPoint:string,Body:{pin:string}}) => {
    const { ContentType, methods, endPoint, Body } = data;
    const Header = {
        "Content-Type": ContentType,
        token:this.state.otpToken,
    };
    const CreateAccount = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    CreateAccount.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(Header)
    );
    CreateAccount.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    CreateAccount.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        methods
    );
    Body &&
        CreateAccount.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),

            JSON.stringify(Body)
        );
    runEngine.sendMessage(CreateAccount.id, CreateAccount);
    return CreateAccount.messageId;
};

handleShowTerms=()=>{
  this.setState({showTermsModal:true})
}

handleHideTerms=()=>{
  this.setState({showTermsModal:false})
}
  // Customizable Area End
}
